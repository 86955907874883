import Head from "next/head";

export default function Metatag({metaDetails})  {
    return (
        <>
        { process.env.NEXT_PUBLIC_META === "true" ?
        <Head>
            /**** Google ****/ 

            <title>{metaDetails[0]?.pagetitle}</title>
            <meta name="title" content={metaDetails[0]?.title} />
            <meta name="description" content={metaDetails[0]?.description} />
            {metaDetails[0]?.keywords && <meta name="keywords" content={metaDetails[0]?.keywords} /> }

            /**** Open Graph (Facebook, LinkedIn) ****/ 

                <meta property="og:type" content="website" />
                <meta property="og:title" content={metaDetails[0]?.title} />
                <meta property="og:url" content={metaDetails[0]?.url} />
                <meta property="og:description" content={metaDetails[0]?.description} />
                <meta property="og:image" content={metaDetails[0]?.image && metaDetails[0]?.image} />
                <meta property="og:site_name" content="EVIndia" /> 
                <meta property="og:locale" content="en_IN" />

            /**** Twitter ****/ 

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={metaDetails[0]?.url} />
            <meta property="twitter:title" content={metaDetails[0]?.title} />
            <meta property="twitter:description" content={metaDetails[0]?.description} />
            <meta property="twitter:image" content={metaDetails[0]?.image && metaDetails[0]?.image} />
            <meta property="twitter:site" content="@EVIndiaonline" />
        </Head>
        : 
       <>
       </> }
       </>
    )
};